import { useSurveyByUser, useGoals, useReminders } from 'lib/hooks';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useUser } from '@auth0/nextjs-auth0/client';

import { addSurveys, setTotalCount, saveUserGoals } from 'lib/redux/actions';
import findGoal from 'lib/utils/findGoals';
import UserProfileTopBanner from 'components/user/UserProfileTopBanner';
import { UserLearningMaterials } from 'components/user/UserLearningMaterials';
import { useIsTrainer } from 'lib/hooks/useRoleAuthorization';
import SurveyRow from 'components/user/SurveyRow';
import NWLSpinner from 'components/NWLSpinner';
import { daysUntilNow } from 'lib/utils/date';
import { Alert } from 'reactstrap';
import Link from 'next/link';
import { useLogger } from 'next-axiom';

const HomePage = () => {
  const { user, error, isLoading } = useUser();
  const { getUserSurveys } = useSurveyByUser();
  const { getReminders } = useReminders();
  const { getUserGoals } = useGoals();
  const dispatch = useDispatch();
  const name = user.name !== user.email ? user.name : user.nickname;

  const log = useLogger();
  log.debug('User logged in', { email: user.email });

  const userSurveys = getUserSurveys({
    email: user?.email,
  });

  const { result: reminders } = getReminders();

  const { result: userGoals } = getUserGoals();

  const isUserTrainer = useIsTrainer({ email: user?.email });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userSurveys?.userSurveys && userGoals?.userGoals) {
      setLoading(false);
      dispatch(addSurveys(userSurveys.userSurveys.surveys));
      dispatch(setTotalCount(userSurveys.userSurveys.surveys.length));
      dispatch(saveUserGoals(userGoals.userGoals.goals));
    }
  }, [userSurveys, userGoals, isUserTrainer]);

  if (isLoading) return <span>Loading user...</span>;
  if (error) return <span>Error getting user.</span>;

  if (loading)
    return (
      <>
        <UserProfileTopBanner email={user.email} name={name} />
        <NWLSpinner fillView actionText="loading" />
      </>
    );

  return (
    <>
      <UserProfileTopBanner email={user.email} name={name} />
      {reminders?.getReminders.reminders.map((reminder) => (
        <Alert className="mt-2 mb-0" color="info">
          You have an invitation to take a survey. Click{' '}
          <Link href={`/survey?code=${reminder.cohortId}`}>here</Link> to start it.
        </Alert>
      ))}
      <div className="flex w-full flex-wrap user-wrapper">
        <div className="user-surveys">
          <div className="user-section-title w-full">Your Surveys</div>
          <div className="user-survey-list">
            {userSurveys.userSurveys.surveys.map((survey, index) => {
              const goal = findGoal({
                userGoals: userGoals.userGoals.goals,
                userSurvey: survey,
              });
              const days = daysUntilNow(new Date(survey.created));
              return (
                <SurveyRow
                  survey={survey}
                  goal={goal}
                  key={survey.id}
                  order={index}
                  inWindow={days < survey.editWindow}
                />
              );
            })}
          </div>
        </div>
        <UserLearningMaterials isTrainer={isUserTrainer} />
      </div>
    </>
  );
};

HomePage.requiresAuth = true;

export default HomePage;
